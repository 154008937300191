import { LinkExternal, PageStateContainer, Text, TextLink, ThemedIcon } from '@metaswiss/ui-kit';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useRef } from 'react';
import { useTheme } from 'styled-components';

import { api } from '../../../../api/msApi';
import { ApiResource } from '../../../../enums/resource.enum';
import { useHeaderOptions } from '../../../../global-state/zustand';
import { useTextTranslation } from '../../../../hooks/use-text-translation/useTextTranslation';
import { getCountryFlagHelper } from '../../../../shared/helpers/getCountryFlag.helper';
import { getFormattedLanguageName } from '../../../../shared/helpers/getFormattedLanguageName.helper';
import { getQueryKey } from '../../../../shared/helpers/getQueryKey.helper';
import { useTenantLabels } from '../../../../shared/tenant-labels/context';
import { tenantConfig } from '../../../../tenantConfig.ts';

import {
  AboutTextContainer,
  AddressWrapper,
  BannerImage,
  ContentWrapper,
  CountryWrapper,
  Details,
  DetailsCard,
  DetailsLogoWrapper,
  DoubleImage,
  Flag,
  HorizontalImagesWrapper,
  ImageContainer,
  LinkTextIcon,
  LinkWrapper,
  Logo,
  LogoContainer,
  MultipleImages,
  OneImageWrapper,
  SingleImage,
  TextOverflowContainer,
  TextWrapper,
  TwoImagesWrapper,
  VideoContainer,
  VideoThumbnail,
} from './aboutUs.styles';

export const AboutUs = () => {
  const { textTranslation, currentLanguage } = useTextTranslation();
  const { appTitle } = useTenantLabels();
  const aboutContainerRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const { setHeaderTitle } = useHeaderOptions();

  useEffect(() => {
    setHeaderTitle(textTranslation('home.aboutUs'));
  }, [setHeaderTitle]);

  const {
    data: aboutUs,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: getQueryKey(ApiResource.ABOUT_US, currentLanguage),
    queryFn: () => api.contentful.getAboutUs(getFormattedLanguageName(currentLanguage)),
  });

  const {
    data: tenantUser,
    isLoading: isLoadingTenant,
    isError: isErrorTenant,
  } = useQuery({
    queryKey: getQueryKey(ApiResource.TENANT_USER),
    queryFn: () => api.users.getTenantUsers(),
  });

  const executeScroll = () => {
    if (aboutContainerRef && aboutContainerRef.current) {
      aboutContainerRef.current.scrollIntoView({ behavior: 'instant' });
    }
  };

  const pageLoading = useMemo(() => isLoading || isLoadingTenant, [isLoading, isLoadingTenant]);

  const pageError = useMemo(() => isError || isErrorTenant, [isError, isErrorTenant]);

  useEffect(() => {
    executeScroll();
  }, []);

  if (!aboutUs || !tenantUser) {
    return null;
  }

  return (
    <PageStateContainer
      isLoading={pageLoading}
      isError={pageError}
      showError={true}
      showLoading={true}
      textTranslation={textTranslation}
      showEmptyState={false}
      onTryAgain={refetch}
      containerRef={aboutContainerRef}
      maxWidth={'64rem'}
    >
      <Text fontWeight="bold" fontSize={'lg'} lineHeight={'h4'} color={theme.v2.text.headingPrimary}>
        {textTranslation('home.aboutUs')}
      </Text>
      <DetailsCard>
        <ImageContainer>
          <BannerImage src={tenantConfig.aboutUsHeaderImg} />
        </ImageContainer>
        <DetailsLogoWrapper>
          <ContentWrapper>
            <LogoContainer>
              <Logo src={tenantConfig.logo} alt={appTitle} />
            </LogoContainer>
            <Details>
              <TextOverflowContainer $width={'75%'}>
                <Text fontWeight="bold" fontSize={'lg'} lineHeight={'h4'} color={theme.v2.text.headingPrimary}>
                  {tenantUser[0]?.endUser?.tenantUser?.name}
                </Text>
              </TextOverflowContainer>
              <CountryWrapper>
                <Flag src={getCountryFlagHelper(tenantUser[0]?.country?.code?.toLowerCase())} alt={'Switzerland'} />
                <Text color={theme.v2.text.bodyPrimary}>{tenantUser[0]?.country?.name}</Text>
              </CountryWrapper>
              <TextOverflowContainer $width={'95%'}>
                <AddressWrapper>
                  <Text fontWeight="semi" fontSize={'sm'} lineHeight={'medium'} color={theme.v2.text.headingPrimary}>
                    {`${tenantUser[0]?.address}, `}
                  </Text>
                  <Text fontSize={'sm'} lineHeight={'medium'} color={theme.v2.text.bodyPrimary}>
                    {tenantUser[0]?.city}
                  </Text>
                </AddressWrapper>
              </TextOverflowContainer>
              <TextLink
                fontWeight={'semi'}
                lineHeight={'medium'}
                textSize="sm"
                onClick={() => window.open(`tel: ${tenantUser[0]?.phoneNumberPrefix}${tenantUser[0]?.phoneNumber}`)}
              >
                {`+${tenantUser[0]?.phoneNumberPrefix.phonePrefix} (0) ${tenantUser[0]?.phoneNumber}`}
              </TextLink>
            </Details>
          </ContentWrapper>
          <LinkWrapper>
            <TextLink
              onClick={() => {
                window.open(tenantConfig.footerCompanyLink(currentLanguage), '_blank');
              }}
            >
              <LinkTextIcon>
                {textTranslation('home.visitWebsite')}
                <ThemedIcon icon={LinkExternal} customStrokeColor={theme.v2.icon.primary} />
              </LinkTextIcon>
            </TextLink>
          </LinkWrapper>
        </DetailsLogoWrapper>
      </DetailsCard>
      <AboutTextContainer>
        <TextWrapper>
          {aboutUs.description.map((item) => (
            <Text fontWeight="bold" color={theme.v2.text.headingPrimary} key={item.heading}>
              {item.heading} <Text color={theme.v2.text.bodyPrimary}>{String(item.description)}</Text>
            </Text>
          ))}
        </TextWrapper>
        <VideoContainer>
          <VideoThumbnail>
            <iframe
              height="100%"
              width="100%"
              src={`https://www.youtube.com/embed/${aboutUs.videoUrl.split('/')[3]}`}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
              style={{
                borderRadius: `${theme.v2.componentsRadius.cards.default}`,
                border: `1px solid ${theme.v2.border.primary}`,
              }}
            />
          </VideoThumbnail>
        </VideoContainer>
        <MultipleImages>
          <HorizontalImagesWrapper>
            <OneImageWrapper>
              <SingleImage src={tenantConfig.aboutUsPlantsImg} />
            </OneImageWrapper>
            <OneImageWrapper>
              <SingleImage src={tenantConfig.aboutUsMicrogreenImg} />
            </OneImageWrapper>
          </HorizontalImagesWrapper>
          <TwoImagesWrapper>
            <DoubleImage src={tenantConfig.aboutUsConferenceImg} />
            <DoubleImage src={tenantConfig.aboutUsMeetingImg} />
          </TwoImagesWrapper>
        </MultipleImages>
      </AboutTextContainer>
    </PageStateContainer>
  );
};
